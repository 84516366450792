import './Modified.css'
import React, {useState, useEffect} from 'react'
import {useAuthContext} from '../../../../../contexts/AuthContext'

import {getModified} from '../../../../../services/ApiClient'

function Modified() {

    const [modifiedData, setModifiedData] = useState([])
    const {language} = useAuthContext()

    const parallaxEffect = () => {
        const getPill = document.querySelector('.BiocontrolledInfo-Modified--image')
        const layer = document.querySelector('.BiocontrolledInfo-Modified--wrapper')

        layer.addEventListener('mousemove', function (e) {
            const pvalueX = (e.pageX * -.1 / 10)
            const pvalueY = (e.pageY * 1 / 30)
            
            getPill.style.transform = `translate3d(${pvalueY}px, ${pvalueX}px, 0)`
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const getModifiedData = await getModified()
            setModifiedData(getModifiedData)
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        parallaxEffect()
    }, [])

    return (
        <>
            <section className="container-fluid BiocontrolledInfo-Modified">
                <div className='row justify-content-center'>
                    <div className='col-sm-8 col-12 BiocontrolledInfo-Modified--wrapper'>
                        <h1 id="title-one">{language === 'ES' ? modifiedData?.title_one : modifiedData?.title_one_eng}</h1>
                        <h1 id="title-two">{language === 'ES' ? modifiedData?.title_two : modifiedData?.title_two_eng}</h1>
                        <h1 id="title-three">{language === 'ES' ? modifiedData?.title_three : modifiedData?.title_three_eng}</h1>
                        <span className='bold' dangerouslySetInnerHTML={{__html: language === 'ES' ? modifiedData?.description : modifiedData?.description_eng}} />
                        <p className='light' dangerouslySetInnerHTML={{__html: language === 'ES' ? modifiedData?.description_two : modifiedData?.description_two_eng}} />
                        <div className='BiocontrolledInfo-Modified--image' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Modified
