import './Footer.css'
import React, {useState, useEffect} from 'react'
import {getRrssInfo} from '../../../../../services/ApiClient'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'


function Footer() {

    const [rrss, setRrss] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            const getRrssData = await getRrssInfo()
            setRrss(getRrssData[0])
        }
        fetchData()



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="container LandigFooter">
            <div className="row justify-content-between">
                <div className='col2 col-sm-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-12'>
                            <ul>
                                <li><a href="#">BIOLETISAN FORTE</a></li>
                                <li><a href="#">BENEFICIOS</a></li>
                                <li><a href="#">COMPRAR</a></li>
                                <li><a href="#">FAQs</a></li>
                                <li><a href="#">ARTÍCULOS</a></li>
                                <li><a href="#">CONTÁCTANOS</a></li>
                            </ul>
                        </div>
                        <div className='col-sm-3 col-12'>
                            <a href='http://grupoleti.com'>GRUPO LETI</a>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-6 LandigFooter-rrss'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Flogo-bioletisan.svg?alt=media&token=23b0f5ff-d1dc-4e69-beda-e46a92ad1c10" onError="this.src = 'https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fno-image.png?alt=media&token=6e518b16-dc11-46e3-83e8-ae4b84a18293';" alt="Bioletisan logo" style={{width: "42rem", float: "right"}} />
                    <div className='row LandigFooter-rrss-icons'>
                        <Link to={{pathname: `${rrss?.facebook}`}} target="_blank" className="Footer__icon facebook--blue icon" />
                        <Link to={{pathname: `${rrss?.instagram}`}} target="_blank" className="Footer__icon instagram--blue icon" />
                        <Link to={{pathname: `${rrss?.linkedin}`}} target="_blank" className="Footer__icon linkedin--blue icon" />
                        <Link to={{pathname: `${rrss?.youtube}`}} target="_blank" className="Footer__icon youtube--blue icon" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
